import React, { useEffect, useState, useContext } from 'react'
import {Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFetch } from '../../hooks'
import { studiKWcolumns, studiKWSchedulingColumns } from '../../utils/constants'
import { WSection } from '../WSection'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import { api } from '../../utils/index'
import { StudiKwFilterDataContext } from '../../HOC/studiKwFiltersContext'
import { WLinkButton } from '../../components/molecules/index'
import { create, all } from 'mathjs'
import {
	Badge,
	Card,
	CardHeader,
	CardFooter,
	Button,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Pagination,
	PaginationItem,
	PaginationLink,
	Progress,
	Table,
	Container,
	Row,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const StudiKWListing = ({ page = '' }) => {
	let history = useHistory()
	const itemPerPage = 10

	const [pages, setPages] = useState(0)

	const [tableData, setTableData] = useState([])

	const { data, info, loading, error } = useFetch({
		url: page === 'scheduling' ? 'studiokw-scheduled/' : 'studiokw/',
		params: { page_size: 10, execute_priority: false },
	})

	const math = create(all)

	const [filteredData, setFilteredData, getFilteredData, queryParams, actualPage, setActualPage] =
		useContext(StudiKwFilterDataContext)

	const renderTooltip = props => (
		<Tooltip {...props}>Se premuto rilancia le keyword mancanti in modalità prioritaria</Tooltip>
	);

	const handlePageClick = (data) => {
		let currentPage = data.selected + 1
		setActualPage(currentPage)
		if (Object.keys(filteredData).length > 0) {
			getFilteredData({
				...queryParams,
				page_size: 10,
				page: currentPage,
				execute_priority: false,
			})
		} else {
			setFilteredData({})
			let path = page === 'scheduling' ? 'studiokw-scheduled/' : 'studiokw/'
			api
				.get(path, {
					params: {
						page_size: 10,
						page: currentPage,
						execute_priority: false,
					},
				})
				.then((response) => {
					setTableData(response.data.results)
					setPages(Math.ceil(response.data.count / itemPerPage))
				})
				.catch((error) => console.error(error))
		}
	}

	const icon = <i className="fa-solid fa-plus"></i>

	const handleStatus = (id, status) => {
		api
			.patch(`studiokw-scheduled/${id}` + '/', {status: status}, {
			})
			.then(() => {
				setTableData(
					getFilteredData({
					...queryParams,
					page_size: 10,
					page: actualPage,
					execute_priority: false,
				}))
			})
			.catch((error) => console.error(error))
	}

	useEffect(() => {
		if (data.length > 0 && tableData.length === 0) {
			setTableData(data)
			setPages(Math.ceil(info.count / itemPerPage))
		}
	}, [data])

	useEffect(() => {
		if (filteredData.results) {
			setTableData(filteredData.results)
			setPages(Math.ceil(filteredData.count / itemPerPage))
		}
	}, [tableData, filteredData])

	const buttonPressed = async e => {
		let id = e.target.id
		await api
			.get(`priority/${id}`, {
				params: {
					id: id,
				},
			})
			.then(() => {
				notify(
					"Richiesta in priorità inviata.",
					'success'
				  )
				window.location.reload()
			})
			.catch((error) => {
				notify(
					error.response.data,
					'error'
			  	)})
	}

	return (
		<>
			{loading && <Spinner animation="grow" />}{' '}
			{tableData && (
				<Row style={{marginBottom: "100px"}}>
				<div className="col pb-5">
					<Card className="shadow">
						<CardHeader className="border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">Studi Keywords</h1>
							</Col>
							<Col md={2} style={{textAlign: "right"}}>
								<Button style={{marginRight: "55px"}} color="info" onClick={() => history.push(page === 'scheduling' ? '/add-scheduling' : '/add-studiokw')} icon={icon}>+ Add new</Button>
							</Col>
						</Row>
						</CardHeader>
							<Table className="align-items-center table-flush" responsive>
								{page === 'scheduling' ? (
								<>
								<thead className="thead-light">
									<tr>
										<th scope="col" style={{fontSize: "12px"}}>Name</th>
										<th scope="col" style={{fontSize: "12px"}}>Files</th>
										<th scope="col" style={{fontSize: "12px"}}>Next Run</th>
										<th scope="col" style={{fontSize: "12px"}}>Mobile</th>
										<th scope="col" style={{fontSize: "12px"}}>Country</th>
										<th scope="col" style={{fontSize: "12px"}}>Brand</th>
										<th scope="col" style={{fontSize: "12px"}}>N°KW</th>
										<th scope="col" style={{fontSize: "12px"}}>Monthly Cost</th>
										<th scope="col" />
									</tr>
								</thead>
									<tbody>
										{tableData.map((item) => (
											<tr key={item.id}>
												<td>
													<Link to={`/add-scheduling/${item.id}`}>{item.nome}</Link>
												</td>
												<td>
													<Link to={`/scheduled_studiokw_list/${item.id}`}>{item.related_studiokw_number}</Link>
												</td>
												<td>{item.next_run.split('T')[0]}</td>
												<td>{item.mobile + ''}</td>
												<td>{item.country}</td>
												<td>{item.brand}</td>
												<td>{item.kw_number}</td>
												<td>{item.cost_min_month}</td>
												<td className="d-flex flex-column">
													<div>
														Stato:{' '}
														{item.status === 'disabled' ? (
															<span style={{ color: 'red' }}>
																<i className="fa-solid fa-circle-xmark fa-xs"></i>
															</span>
														) : (
															<span style={{ color: 'green' }}>
																<i className="fa-solid fa-circle-check fa-xs"></i>
															</span>
														)}
													</div>
													<Button
														className="btn-sm"
														variant="link"
														onClick={() =>
															handleStatus(
																item.id,
																item.status === 'disabled' ? 'enabled' : 'disabled'
															)
														}
													>
														{item.status === 'disabled' ? 'Abilita' : 'Disabilita'}
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</>
								) : (
								<>
								<thead className="thead-light">
									<tr>
										<th scope="col" style={{fontSize: "12px"}}>Name</th>
										<th scope="col" style={{fontSize: "12px"}}>Date</th>
										<th scope="col" style={{fontSize: "12px"}}>Country</th>
										<th scope="col" style={{fontSize: "12px"}}>Status</th>
										<th scope="col" style={{fontSize: "12px"}}>Progress</th>
										<th scope="col" />
									</tr>
									</thead>
									<tbody>
										{tableData.map((item) => (
											<tr className={item.status == 'ARCHIVED' ? "archivedStudioKw" : ""} key={item.id}>
												<td>
													{item.status == 'ARCHIVED' ? (<p>{item.nome}</p>)
													:(<Link to={`/add-studiokw/${item.id}`}>{item.nome}</Link>)}
												</td>
												<td>{item.created.split("T")[0]}</td>
												<td>{item.country}</td>
													<td>
												<Badge color="" className="badge-dot mr-4">
												<i className={item.status == 'EXPORTED' ? "bg-success" : item.status == 'ERROR' ? "bg-danger" : "bg-warning"} />
												{item.status}
											</Badge>
												</td>
												<td>
												<div className="d-flex align-items-center">
													<div>
														<Progress
															max="100"
															value={(math.evaluate(item.progress) * 100).toString()}
															barClassName={math.evaluate(item.progress) == 1 ? "bg-success" : "bg-warning"}
														/>
														{item.progress}
													</div>
												</div>
												</td>
												<td className="d-flex justify-content-evenly">
													{item.status !== 'ARCHIVED' ?
														<WLinkButton
															label="Duplica"
															url={`/duplicate/${item.id}`}
															variant="light"
															className="btn-sm"
														/>
													:<div></div>}
													{/*<OverlayTrigger placement="top" overlay={renderTooltip}>*/}
													{/*{parseInt(item.progress.split('/')[1]) - parseInt(item.progress.split('/')[0]) <= 20*/}
													{/*	&& parseInt(item.progress.split('/')[1]) - parseInt(item.progress.split('/')[0]) > 0*/}
													{/*	&& !item.priority ? //se mancano meno di 20 keyword, più di 0 e non è già in priorità*/}
													{/*	<Button style={{'marginLeft':'1em'}} className='btn-sm btn btn-light' id={item.id} onClick={buttonPressed}>Refresh</Button>*/}
													{/*	:<Button style={{'marginLeft':'1em'}} disabled className='btn-sm btn btn-light' id={item.id} onClick={buttonPressed}>Refresh</Button>}*/}
													{/*</OverlayTrigger>		*/}
												</td>
											</tr>
										))}
									</tbody>
								</>
								)}
							</Table>
							<ReactPaginate 
								previousLabel={'<'}
								nextLabel={'>'}
								breakLabel={'...'}
								pageCount={pages}
								forcePage={actualPage - 1}
								onPageChange={handlePageClick}
								containerClassName={'pagination justify-content-center pt-5 pb-3'} // classname of the pagination container (ul)
								pageClassName={'page-item'} // classname on tag li of each page element
								pageLinkClassName={'page-link'} // classname on tag a of each page element
								previousClassName={'page-item'}
								previousLinkClassName={'page-link'}
								nextClassName={'page-item'}
								nextLinkClassName={'page-link'}
								breakClassName={'page-item'}
								breakLinkClassName={'page-link'}
								activeClassName={'active'}
							/>
							</Card>
					</div>
				</Row>
			)}
			<ToastContainer />
		</>
	)
}

export default StudiKWListing

import React from 'react'
import './App.css'
import './App.scss'

import auth from './auth/service'

import Footer from './components/Footer'
import HistoricalReport from './containers/HistoricalReportForm/HistoricalReport'
import KeywordIdeas from './containers/KeywordIdeas/KeywordIdeas'
import CreateVolumeRetrieve from './containers/VolumeRetrieve/CreateVolumeRetrieve'
import VolumeRetrieve from './containers/VolumeRetrieve/VolumeRetrieve'
import VolumeRetrieveListing from './containers/VolumeRetrieve/VolumeRetrieveListing'
import VolumeRetrieveForm from './containers/VolumeRetrieve/VolumeRetrieveForm'
import ScheduledStudiokwDetail from './containers/ScheduledStudiokwDetail/ScheduledStudiokwDetail'
import ScheduledStudioKwIndexDetail from './containers/StudiKwIndex/ScheduledStudioKwIndexDetail'
import DuplicateKeywordsGpt from './containers/DuplicateKeywordsGptForm/DuplicateKeywordsGpt'
import KeywordClustering from './containers/KeywordClustering/KeywordClustering'
import BulkIndexing from './containers/BulkIndexing/BulkIndexing'
import SemRush from './containers/SemRush/SemRush'
import HtmlCleaner from './containers/HtmlCleaner/HtmlCleaner'
import { Route, Router, Switch } from 'react-router-dom'
import history from './history'
import Home from './main/Home'
import AddStudiokw from './main/AddStudiokw'
import AddScheduling from './main/AddScheduling'
import AddSchedulingIndex from './main/AddSchedulingIndex'
import BigQueryExporter from './main/BigQueryExporter'
import BigQueryExporterFile from './main/BigQueryExporterFile'
import LazyLoad from './main/LazyLoad'
import Scheduling from './main/Scheduling'
import SchedulingIndex from './main/SchedulingIndex'
import { Container } from 'reactstrap'
import AppTaggingTool from './AppTaggingTool'
import CreateReport from './tagging_tool/containers/CreateReport/CreateReport'
import DimensionGroups from './tagging_tool/containers/DimensionGroups/DimensionGroupsAdmin'
import Sidebar from './containers/Sidebar/Sidebar'

import Listing from './tagging_tool/containers/Listing/Listing'
import DetailReport from './tagging_tool/containers/DetailReport/DetailReport'
import DetailRules from './tagging_tool/containers/DetailRules/DetailRules'
import SeoReport from './tagging_tool/containers/SeoReport/SeoReport'
import DimensionGroupInfo from "./tagging_tool/containers/DimensionGroups/DimensionGroupInfo/DimensionGroupInfo";

import AlertEvents from './containers/SeoMonitoring/AlertEvents'
import AlertEventsDetail from './containers/SeoMonitoring/AlertEventsDetail'
import SiteUrlManage from './containers/SeoMonitoring/SiteUrlManage'
import SiteUrlList from './containers/SeoMonitoring/SiteUrlList'
import EmailAlertsRead from './containers/SeoMonitoring/EmailAlertsRead'

import { GTM_ID } from './utils/index'
import TagManager from 'react-gtm-module'
import routes from "./links";
import StudiokwEmailAlertsRead from "./main/StudiokwEmailAlertsRead";
import KeywordClusteringFile from "./containers/KeywordClustering/KeywordClusteringFile";

const tagManagerArgs = {
  gtmId: GTM_ID,
}

const environment = process.env

if (environment.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}


class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout() {
    auth.logout()
  }

  componentDidMount() {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      auth.renewSession().then(() => {
        if (!auth.userProfile) {
          auth.getProfile().then(() => {
            this.setState({
              profile: auth.userProfile,
              userGroup: auth.userGroup,
            })
          })
        } else {
          this.setState({
            profile: auth.userProfile,
            userGroup: auth.userGroup,
          })
        }
      })
    }
  }

  render() {
    return (
      <div className='main' id="main">
        <Sidebar isAuthenticated={false} userGroup={this.state.userGroup} onLogout={this.handleLogout} routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("./assets/img/brand/cropped-logo.png"),
          imgAlt: "..."
        }}
        id="sidebar"/>
        <div className="main-content" id="main-content">
          <Router history={history}>
            <Container fluid>
              <Switch>
                {/*<Route path={'/historical-retrieve'} component={HistoricalReport} />*/}
                <Route path={'/keyword-ideas'} component={KeywordIdeas} />
                <Route path={'/scheduling'} component={Scheduling} />
                <Route path={'/scheduled_studiokw_list/:id?'} component={ScheduledStudiokwDetail}/>
                <Route path={'/scheduling_index'} component={SchedulingIndex} />
                <Route path={'/scheduling_index_list/:id?'} component={ScheduledStudioKwIndexDetail}/>
                <Route path={'/volume-retrieve-listing'} component={VolumeRetrieveListing} />
                <Route path={'/create-volume-retrieve'} component={CreateVolumeRetrieve} />
                <Route path={'/create-volume-retrieve-form'} component={VolumeRetrieveForm} />
                <Route path={'/volume-retrieve'} component={VolumeRetrieve} />
                <Route path={'/duplicate-keywords-gpt'} component={DuplicateKeywordsGpt} />                
                <Route path={'/keyword-clustering'} component={KeywordClustering} />
                <Route path={'/keyword-clustering-file/:id?'} component={KeywordClusteringFile} />
                <Route path={'/add-studiokw/:id?'} component={AddStudiokw} />
                <Route path={'/add-scheduling/:id?'} component={AddScheduling} />
                <Route path={'/add-scheduling-index/:id?'} component={AddSchedulingIndex} />
                <Route path={'/duplicate/:id?'} component={AddStudiokw} />
                <Route path={'/lazy-load'} component={LazyLoad} />
                <Route path={'/bigquery-exporter'} component={BigQueryExporter} />
                <Route path={'/bigquery-exporter-file/:id?'} component={BigQueryExporterFile} />
                <Route path={'/bulk-indexing'} component={BulkIndexing} />
                <Route path={'/semrush'} component={SemRush} />
                <Route path={'/html-cleaner'} component={HtmlCleaner} />
                <Route path={'/seo-monitoring/site-urls/edit/:id'} exact component={SiteUrlManage} />
                <Route path={'/seo-monitoring/site-urls/edit'} exact component={SiteUrlManage} />
                <Route path={'/seo-monitoring/site-urls'} exact component={SiteUrlList} />
                <Route path={'/seo-monitoring/alert-events/:siteUrlId'} exact component={AlertEvents} />
                <Route path={'/seo-monitoring/alert-events/:siteUrlId/:dayDate'} exact component={AlertEventsDetail} />
                <Route path={'/seo-monitoring/email-alerts/:id?'} component={EmailAlertsRead} />
                <Route path={'/studiokw/email-alerts/:id?'} component={StudiokwEmailAlertsRead} />

                <Route path={'/reporting'} exact component={Listing} />
                <Route path={'/reporting-dimension-groups'} exact render={() => <DimensionGroups/>}/>
                <Route path={'/reporting-create'} component={CreateReport} />
                <Route path={"/reporting/reports/seo/:id"} exact component={SeoReport}/>
                <Route path={"/reporting/dimension-groups/info/:name"} exact component={DimensionGroupInfo} />
                <Route path={"/reporting/dimension-groups"} exact render={() => <DimensionGroups/>} />
                <Route path={"/reporting/saved-dimensions/:id"} exact component={DetailReport} />
                <Route path={"/reporting/custom-dimensions/:id"} exact component={DetailReport} />
                <Route path={"/reporting/custom-metrics/:id"} exact component={DetailReport}/>
                <Route path={"/reporting/custom-rules/:id"} exact component={DetailRules}/>
                
                <Route path={'/'} component={Home} />
              </Switch>
            </Container>
          </Router>
        </div>
        <div id="modal-portal" />
        <Footer />
      </div>
    )
  }
}

export default App
